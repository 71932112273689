import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import GlobalTiles from "../../components/globalTiles"
import ImageTween from "../../components/imageTween"
import AboutInstaGrid from "../../components/aboutInstaGrid"

const About = () => {
  const lang = "pl"

  return (
    <Layout lang={lang} translation="/about/">
      <SEO title="O Nas" />

      <section className="home-top">
        <div className="home-top__wrapper global__padd">
          <h2>
            UNITY <br /> <span>Centre</span>
          </h2>
          <h2>Krakowski Kompleks biznesowy</h2>
        </div>
        <div className="global__padd">
          <ImageTween
            src={require("../../assets/images/about-top-image.jpg")}
            srcSet={require("../../assets/images/about-top-image@2x.jpg")}
            alt=""
          />
        </div>
        <div className="home-top__desc global__padd">
          <div className="row">
            <div className="col-md-6">
              <h1>
                Witamy w UNITY CENTRE - największym, wielofunkcyjnym kompleksie
                budynków w Krakowie
              </h1>
            </div>
            <div className="col-md-5 offset-md-1">
              <div className="home-top__inner">
                <p>
                  Oferujemy infrastrukturę biurową, mieszkaniową i handlową.{" "}
                  <br /> Na 50 000 m² zaplanowaliśmy nowoczesne przestrzenie do
                  pracy, lokale usługowe, luksusową rezydencję z mieszkaniami do
                  wynajęcia, hotel i sprzyjające relaksowi przestrzenie
                  publiczne.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-content global__padd">
        <div className="row no-gutters about-content__row">
          <div className="col-lg-4">
            <ImageTween
              src={require("../../assets/images/about-content-image-6.jpg")}
              srcSet={require("../../assets/images/about-content-image-6@2x.jpg")}
              alt=""
            />
          </div>
          <div className="col-lg-8">
            <div className="about-content__inner sticky-wrapper">
              <h2>Zostań częścią fascynującej historii miejsca.</h2>
              <p>
                Po 45 latach od rozpoczęcia pierwszych prac, budowa najwyższego
                wieżowca w Krakowie została ostatecznie zakończona. UNITY Tower
                jest teraz częścią nowoczesnego kompleksu UNITY CENTRE, w skład
                którego wchodzą też dwa dodatkowe niższe biurowce, hotel i
                luksusowy apartamentowiec. UNITY CENTRE rozpoczęło nowy etap w
                swojej historii.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="about-insta-grid global__padd">
        <AboutInstaGrid />
      </section>

      <section className="about-content global__padd">
        <div className="row no-gutters about-content__row">
          <div className="col-lg-5">
            <ImageTween
              src={require("../../assets/images/about-content-image-1.jpg")}
              srcSet={require("../../assets/images/about-content-image-1@2x.jpg")}
              alt=""
            />
          </div>
          <div className="col-lg-7">
            <div className="about-content__inner sticky-wrapper">
              <h2>Na wyciągnięcie dłoni</h2>
              <p>
                W UNITY CENTRE wszystko jest w jednym miejscu. Adres
                Lubomirskiego 16-24 przywita Was placem UNITY Square, na którym
                znajdziecie restauracje, kawiarnie i przestrzeń publiczną.
              </p>
              <p>
                Zaplanowaliśmy także szeroki wachlarz udogodnień, od sklepów,
                przez rozrywkę po strefy gastronomiczne i usługowe. Szybkie
                połączenie z całym Krakowem ułatwią liczne linie tramwajowe,
                autobusowe, pociągowe i mikromobilność.
              </p>
            </div>
          </div>
        </div>

        <div className="row no-gutters about-content__row">
          <div className="col-lg-8">
            <div className="about-content__inner sticky-wrapper">
              <h2>
                Fascynująca opowieść o polskim Manhattanie zaczyna się w 1968
                roku.
              </h2>
              <p>
                Wtedy to ogłoszono konkurs architektoniczny na projekty budynku
                Naczelnej Organizacji Technicznej (NOT).
                <br />
                <br />
                Zwyciężyła praca przygotowana przez zespół Zdzisława Arcta.
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <ImageTween
              src={require("../../assets/images/about-content-image-3.jpg")}
              srcSet={require("../../assets/images/about-content-image-3@2x.jpg")}
              alt=""
            />
          </div>
        </div>

        <div className="row no-gutters about-content__row flex-column-reverse flex-md-row">
          <div className="col-lg-5">
            <ImageTween
              src={require("../../assets/images/about-content-image-2.jpg")}
              srcSet={require("../../assets/images/about-content-image-2@2x.jpg")}
              alt=""
            />
          </div>
          <div className="col-lg-7">
            <div className="about-content__inner sticky-wrapper">
              <h2>Miejsce z historią</h2>
              <p>
                W połowie lat sześćdziesiątych te współrzędne na mapie stały się
                miejscem realizacji odważnej wizji architektonicznej. Po 50
                latach od tego momentu UNITY CENTRE wraz z UNITY Tower powróciły
                jako ikony architektury w samym sercu miasta.
              </p>
              <p>
                UNITY CENTRE charakteryzuje się współczesnym podejściem do
                projektowania przestrzeni. Miejsce tworzono w wysublimowany
                sposób, łącząc najlepsze przykłady światowej architektury i
                ducha miasta, jednocześnie podkreślając ekspresyjną prostotę
                budynków. Dzięki kosmopolitycznemu podejściu, architekci
                wykorzystali okazję i przywrócili budynkowi jego prestiż jako
                najwyższej budowli w Krakowie.
              </p>
            </div>
          </div>
        </div>

        <div className="row no-gutters align-items-center about-content__row">
          <div className="col-lg-7">
            <div className="about-content__inner sticky-wrapper">
              <p>
                Architekci zaproponowali imponujący 92-metrowy wieżowiec
                górujący nad miastem. Projekt powstał w nietypowej jak na tamte
                czasy skali - wieżowiec miał mieć powierzchnię 20 000 m² oraz
                salę kongresową, przestrzeń wystawienniczą, hotel, kompleks
                gastronomiczny, a nawet parking podziemny. Niestety ze względu
                na brak funduszy prace budowlane wstrzymano w 1979 roku. Nad
                wieżowcem zawisło widmo niepewności. Budowlę zaczęto nazywać
                „szkieletorem”.
              </p>
            </div>
          </div>
          <div className="col-lg-5">
            <ImageTween
              src={require("../../assets/images/about-content-image-4.jpg")}
              srcSet={require("../../assets/images/about-content-image-4@2x.jpg")}
              alt=""
            />
          </div>
        </div>

        <div className="row no-gutters about-content__row flex-column-reverse flex-md-row">
          <div className="col-lg-5">
            <ImageTween
              src={require("../../assets/images/about-content-image-5.jpg")}
              srcSet={require("../../assets/images/about-content-image-5@2x.jpg")}
              alt=""
            />
          </div>
          <div className="col-lg-7">
            <div className="about-content__inner sticky-wrapper">
              <p>
                W 2005 roku Treimorfa Projekt (w którym udziały objął lokalny
                deweloper GD&K Group) został właścicielem „szkieletora”. Jednak
                każdy, kto podejrzewał, że historia PRL-owego wieżowca wkrótce
                dobiegnie końca, może być zaskoczony. Nowy właściciel nie
                oznaczał końca wyzwań. Pomimo ciekawego pomysłu na
                zagospodarowanie budynku, rozpoczęły się liczne spory, które
                trwały przez lata. Czynnikiem zapalnym okazały się protesty
                konserwatora zabytków i ekologów, a także lokalnej społeczności.
              </p>
              <p>
                Z biegiem lat pojawiło się wiele pomysłów na dokończenie budowy.
                Niektóre z nich były zbyt wizjonerskie, aby można było je
                sfinalizować. Jedyną wizją, która odniosła sukces, okazał się
                projekt niemieckiego architekta Hansa Kollhfa. Zakładał on, że
                cały wieżowiec zostanie pokryty kamienną fasadą i podniesiony o
                kilka kondygnacji. Później kompleks został nazwany UNITY CENTRE.
              </p>
            </div>
          </div>
        </div>

        <div className="row no-gutters about-content__row">
          <div className="col-lg-7">
            <div className="about-content__inner sticky-wrapper">
              <h2>Architektura i design</h2>
              <p>Współczesne podejście do ekspresyjnej prostoty</p>
              <p>
                Zbudowana ze stali i kamienia, konstrukcja UNITY CENTRE
                nawiązuje do przedwojennych tradycji architektonicznych Krakowa.
                Projektanci z DDJM Studio czerpali inspirację z historycznego
                dorobku industrializacji i stylu Art Déco.
              </p>
              <p>
                Architektura urzeka geometryczną symetrią, przestrzenną
                dyscypliną, w której forma podąża za funkcją, dążeniem do
                doskonałości i ekspresyjnej prostoty, lokalną tradycją
                architektoniczną i estetyką awangardową.
              </p>
              <p>
                „Myśląc o tym, jak powinien wyglądać obiekt będący ikoną
                nowoczesnego ośrodka biznesowego, jakim stał się Kraków, zarazem
                jednak miasta o bogatych tradycjach historycznych, uznaliśmy, że
                musi on być eklektyczny. Tak jak większość znaczących budynków
                Krakowa, z którymi jestem zżyty, chcieliśmy, aby subtelnie
                wpisywał się w panoramę miasta. Miał być zarazem rozpoznawalny i
                dumny jak nowojorskie drapacze chmur.
              </p>
              <p>
                Marek Dunikowski, architekt
                <br /> DDJM
              </p>
            </div>
          </div>
          <div className="col-lg-5">
            <ImageTween
              src={require("../../assets/images/about-content-image-8.jpg")}
              srcSet={require("../../assets/images/about-content-image-8@2x.jpg")}
              alt=""
            />
          </div>
        </div>
      </section>

      <GlobalTiles lang={lang} />
    </Layout>
  )
}

export default About
